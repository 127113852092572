'use strict'

###*
 # @ngdoc object
 # @name mundoActions
 # @description

###
angular
  .module 'mundoActions', []
  .run [
    'MundoActions'
    'UiHelpers'
    'MessageData'
    'StatusData'
    'SessionData'
    'AssetData'
    'AssetManager'
    'UnitsManager'
    'UnitPermissions'
    'UserPermissions'
    'DeviceTypeManager'
    '$q'
    (
      MundoActions,
      UiHelpers,
      MessageData,
      StatusData,
      SessionData,
      AssetData,
      AssetManager,
      UnitsManager,
      UnitPermissions,
      UserPermissions,
      DeviceTypeManager,
      $q
    ) ->
      MundoActions.register 'debug_unit_status', (options, action) ->
        StatusData.getList
          'filter[]': "_id,eq:id #{options.data.id}"
        .then (result) ->
          if result.length
            UiHelpers.openDialog
              template: 'mundo-actions/views/json-dialog.tpl.html'
              data:
                json: result[0].plain()

      MundoActions.register 'debug_unit_message', (options, action) ->
        MessageData.getList
          'filter[]': "_id,eq:id #{options.data.id}"
        .then (result) ->
          if result.length
            UiHelpers.openDialog
              template: 'mundo-actions/views/json-dialog.tpl.html'
              data:
                json: result[0].plain()

      MundoActions.register 'debug_unit_session', (options, action) ->
        SessionData.getList
          'filter[]': "_id,eq:id #{options.data.id}"
        .then (result) ->
          if result.length
            UiHelpers.openDialog
              template: 'mundo-actions/views/json-dialog.tpl.html'
              data:
                json: result[0].plain()

      MundoActions.register 'asset_details', (options, action) ->
        AssetData.one(options.data.id).get().then (result) ->
          if result.assetData?
            for data in result.assetData
              if data.type? and AssetManager.dataTemplates[data.type]?
                data._template = AssetManager.dataTemplates[data.type]

          UiHelpers.openDialog
            template: 'mundo-actions/views/asset-details-dialog.tpl.html'
            data:
              asset: result
              assetDataTemplateOptions: AssetManager.templateOptions

      MundoActions.register 'unit_devices_set_profile', (options, action) ->
        UnitsManager.getOne(options.data.id).then (result) ->
          if result.unitDevices?
            for unitDevice in result.unitDevices
              unitDevice._deviceTypeProfile = unitDevice.deviceTypeProfile

          profiles = {}

          UiHelpers.openDialog
            template: 'mundo-actions/views/unit-devices-set-profile-dialog.tpl.html'
            data:
              hasPermission: UserPermissions.check('set unit device profile') or
                UnitPermissions.check('set unit device profile', result.id)
              unit: result
              profiles: profiles
              getProfiles: (unitDevice) ->
                if not profiles[unitDevice.id]?
                  profiles[unitDevice.id] = DeviceTypeManager.getProfilesByType(unitDevice.device.deviceType)

                return profiles[unitDevice.id]
              isProfileValid: (unitDevice, deviceTypeProfileCode) ->
                if not unitDevice.deviceTypeProfileSwitchingAllowed
                  return false

                if unitDevice.allowedDeviceTypeProfiles?
                  allowedProfiles = _.values unitDevice.allowedDeviceTypeProfiles

                  if allowedProfiles.length
                    for x in allowedProfiles when x == deviceTypeProfileCode
                      return true

                    return false

                return true
            onConfirm: () ->
              for unitDevice in result.unitDevices
                if unitDevice._deviceTypeProfile != unitDevice.deviceTypeProfile
                  UnitsManager.setDeviceProfile result.id, unitDevice.id, unitDevice._deviceTypeProfile

      MundoActions.register 'unit_devices_set_parameters', (options, action) ->
        UnitsManager.getOne(options.data.id).then (result) ->
          data =
            hasPermission: UserPermissions.check('set unit device parameters') or
              UnitPermissions.check('set unit device parameters', result.id)
            configurableOnly: not UserPermissions.check('manage all MundoMosaUnitBundle:Unit entities')
            unit: result
            unitDevices: result.unitDevices.filter (v) -> !v.deletedAt?
            deviceTypes: []
            deviceTypeProfiles: []
            selectedUnitDevices: []
            selectedParameters: []

          data.getUnitDeviceParameters = (unitDevice) ->
            deviceTypeProfiles = data.deviceTypeProfiles
              .filter (v) ->
                v.code == "#{unitDevice.device.deviceType}_#{unitDevice.deviceTypeProfile}"

            if deviceTypeProfiles.length and deviceTypeProfiles[0].parameters?
              parameters = deviceTypeProfiles[0].parameters

              if data.configurableOnly
                parameters = parameters.filter (v) -> v.options? and v.options.configurable

              return parameters

            return []

          data.reloadDeviceParameters = ->
            data.selectedUnitDevices = data.unitDevices.filter (v) -> v._selected
            parameters = {}

            for unitDevice in data.selectedUnitDevices
              for param in data.getUnitDeviceParameters unitDevice
                if not parameters[param.code]?
                  parameters[param.code] = param

            data.selectedParameters = _.values parameters

          data.getParameterById = (unitDevice, param) ->
            parameter = data.getUnitDeviceParameters(unitDevice).filter (v) -> v.code == param.code

            if parameter.length
              value = if unitDevice.parameters[param.code]? then unitDevice.parameters[param.code] else null

              return {
                parameter: param
                value: value
              }

            return null

          DeviceTypeManager.getListPromise().then (responseDeviceTypes) ->
            data.deviceTypes = _.values responseDeviceTypes.results
            data.deviceTypeProfiles = []

            for type in data.deviceTypes
              for profile in _.values type.profiles
                data.deviceTypeProfiles.push
                  code: "#{type.code}_#{profile.code}"
                  parameters: profile.parameters

          UiHelpers.openDialog
            template: 'mundo-actions/views/unit-devices-set-parameters-dialog.tpl.html'
            data: data
            onConfirm: () ->
              for unitDevice in data.selectedUnitDevices
                parameters = {}

                for param in data.selectedParameters
                  deviceParam = data.getParameterById unitDevice, param

                  if not deviceParam?
                    continue

                  parameters[param.code] = deviceParam.value

                  if param._value?
                    switch param.type
                      when 'integer'
                        parameters[param.code] = parseInt(param._value, 10)
                      when 'number'
                        parameters[param.code] = parseFloat(param._value)
                      when 'choice'
                        parameters[param.code] = param._value
                      when 'text'
                        parameters[param.code] = param._value
                      when 'file'
                        if param._value.data?
                          prefixLength = param._value.data.lastIndexOf(',') + 1
                          parameters[param.code] = param._value.data.substr prefixLength
                      else
                        parameters[param.code] = param._value

                if Object.keys(parameters).length > 0
                  UnitsManager.saveDeviceParameters result.id, unitDevice.id, parameters
  ]
